@import "colors.scss";
@import "responsive.scss";
@import "bootstrap-override.scss";

@import "~bootstrap/scss/bootstrap.scss";

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.3/font/bootstrap-icons.css");

html {
  position: relative;
  min-height: 100%;
}

body {
  margin-bottom: 22rem;
}

.footer-wrapper.bg-dark{
  position: absolute;
  bottom: 0;
  width: 100%;
  background: linear-gradient(0deg, $gray-800, $gray-900);
}


// TODO: data-b-theme
body.bg-light {
  color: #111;
}

// Typography

h1 {
  font-weight: 900;
}

h2 {
  font-weight: 700;
}

// Nav

.navbar-toggler {
  border-radius: 0.4rem;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
}

.navbar-toggler {
  border-width: 2px;
}

[data-bs-theme="dark"] .navbar-toggler {
  border-color: $navbar-dark-color;
}

[data-bs-theme="light"] .navbar-toggler {
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.15)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
  }
}

// Hero

.btn-contact {
  font-size: 1.2rem;
  border-radius: 0.5rem;
  padding: 0.8rem 0;

  background-color: $blue;
  border-color: $blue;
  color: #fff;

  &:hover {
    background-color: darken($blue, 10%);
    border-color: darken($blue, 10%);
    color: #fff;
  }

  &:active,
  &:focus,
  &:active:focus {
    background-color: darken($blue, 15%);
    border-color: darken($blue, 15%);
    color: #fff;
    box-shadow: none;
  }
}

.btn-phone {
  background-color: $green;
  border-color: $green;

  &:hover {
    background-color: darken($green, 10%);
    border-color: darken($green, 10%);
  }

  &:active,
  &:focus,
  &:active:focus {
    background-color: darken($green, 15%);
    border-color: darken($green, 15%);
  }
}

// Home page video background
// Mostly from https://www.divinectorweb.com/2021/10/bootstrap-5-homepage-video-background.html

.video-container {
  $hero-video-height: 600px;
  min-height: $hero-video-height;
  position: relative;
  overflow: hidden; /* Ensure the content inside doesn't overflow */

  .video-background {
    width: 100%; /* Ensure the video stretches full width */
    height: 100%; /* Ensure the video stretches full height */
    object-fit: cover; /* Maintain aspect ratio and cover the area */
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 0;
    transform: translate(-50%, -50%);
    opacity: 0.4;
  }

  .hero {
    text-align: center;
    position: relative;
    z-index: 1; /* Ensure hero content is on top of the video */
    p {
      padding-top: 1.2rem;
      line-height: 2rem;
    }
  }
}

// Client logos

.client-logos {
  img {
    width: 8%;
    height: 80px;
    margin: 0 2%;
    object-fit: contain;
  }
}

.client-logos.client-logos-small {
  padding: 40px;
  img {
    width: 4%;
    height: 40px;
    margin: 0.5% 0.5%;
    object-fit: contain;
  }
}

@include media-breakpoint-down(md) {
  .client-logos {
    img {
      width: 10%;
      height: 60px;
      margin: 0 1%;
    }
  }
}

// Contact form

#contact-form #message {
  height: 150px;
}

// Map

.map {
  height: 400px;
  width: 100%;
}
