$enable-responsive-font-sizes: true;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1200px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 1100px,
);

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 6,
  7: $spacer * 7,
  8: $spacer * 8,
  9: $spacer * 9,
  10: $spacer * 10,
);

// Typography

// TODO: download the font and add it to the project
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
$font-family-base: 'DM Sans', sans-serif;
$font-size-base: 1.3rem;
$line-height-base: 1.4;

// @import "~/bootstrap/scss/functions";
// @import "~bootstrap/scss/_variables";
// @import "~/bootstrap/scss/utilities";

// // Forms

// $input-bg:                              $gray-200;
// $input-disabled-bg:                     $gray-200 !default;
// $input-disabled-border-color:           null !default;

// .form-control {
//   margin-bottom: 0.5rem;
// }

// $input-color:                           #000;
// $input-border-color:                    $gray-400 !default;
// $input-border-width:                    $input-btn-border-width !default;
// $input-box-shadow:                      $box-shadow-inset !default;

// $input-border-radius:                   $border-radius !default;
// $input-border-radius-sm:                $border-radius-sm !default;
// $input-border-radius-lg:                $border-radius-lg !default;

// $input-focus-bg:                        $input-bg;
// $input-focus-border-color:              tint-color($component-active-bg, 50%) !default;
// $input-focus-color:                     $input-color;
// $input-focus-width:                     $input-btn-focus-width !default;
// $input-focus-box-shadow:                $input-btn-focus-box-shadow !default;

// $input-placeholder-color:               $gray-600 !default;
// $input-plaintext-color:                 $body-color !default;

















// $utilities: (
//   "width": (
//     property: width,
//     responsive: true,
//     values: (
//       auto: auto,
//       initial: initial,
//       inherit: inherit,
//       5: 5%,
//       10: 10%,
//       20: 20%,
//       25: 25%,
//       50: 5%,
//       75: 75%,
//       100: 100%,
//     )
//   )
// );
