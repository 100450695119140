$primary:  #1e48d3;
$secondary: #8dd31e;
$primary-light: scale-color($primary, $lightness: 10%);
$secondary-light: scale-color($secondary, $lightness: 85%);

$body-color: #fff;
$body-bg: #111;

$navbar-dark-color: rgba(255, 255, 255, 0.7);
$navbar-dark-hover-color: rgba(255, 255, 255, 1);

$blue: #1e48d3;
$green: #8dd31e;
